import React from "react";
import { motion } from "framer-motion";
import styles from "./SwipeImage.module.css";

const variants1 = {
    initial: { clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)` },
    animate: {
        clipPath: `polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)`,
        transition: {
            delay: 1,
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            repeatDelay: 3,
        },
    },
};

const variants2 = {
    initial: { clipPath: `polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)` },
    animate: {
        clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
        transition: {
            delay: 1,
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            repeatDelay: 3,
        },
    },
};

const SwipeImage = ({ initialImage, finalImage }) => {
    return (
        <div className={styles.imageContainer}>
            <motion.img
                className={styles.image}
                src={initialImage}
                alt="Initial state"
                initial="initial"
                animate="animate"
                variants={variants1}
            />
            <motion.img
                className={styles.image}
                src={finalImage}
                alt="Final state"
                initial="initial"
                animate="animate"
                variants={variants2}
            />
        </div>
    );
};

export default SwipeImage;
