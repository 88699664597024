import React from "react";
import styles from "./LandingPage.module.css";
import newPhone from '../../../images/phone.webp'
import brokenPhone from '../../../images/brokenPhone.webp'
import Button from "../../Button/Button.js";
import SwipeImage from "../../SwipeImage/SwipeImage.js";

const LandingPage = () => {

    const handleClickForLocations = () => {
        window.location.href = "https://www.quikfix.repair/locations/";
    };
    const handleClickForQuote = () => {
        window.location.href = "https://www.quikfix.repair/contact/";
    };
    return (
        <div className={styles.landingContainer} onClick={handleClickForQuote}>
            <header className={styles.header}>
                <h2>Quik Fix</h2>
                <p>Tucson's premier local experts.</p>
            </header>
            <SwipeImage initialImage={brokenPhone} finalImage={newPhone} />
            <div>
                <Button onClick={handleClickForLocations}>
                    Visit Us Today!
                </Button>
                <Button>
                    Get A Quote!
                </Button>
            </div>
            <footer className={styles.footer}>
                <p>&copy; {new Date().getFullYear()} Quik Fix</p>
            </footer>
        </div>
    );
}

export default LandingPage;
